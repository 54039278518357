/* body {
	background-color: #000;
} */
#wrap {
	width: 800px;
	margin: 20px auto;
}
#form-wrap {
	width: 800px;
	margin: 0 auto;
	display: block;
	text-align: center;
	padding: 0;
	font-family: 'Helvetica', Arial, sans-serif;
}
form {
	background-color: rgba(235,99,0,1);
	/* border: 1px solid rgba(235,99,30,1); */
	border: 1px solid #999;
	display: flex;
	margin: 5px auto;
	padding: 15px 25px;
	width: calc(100% - 50px);
	/*width: 100%;*/
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
form label {
	position: relative;
	display: block;
	margin: 0 0 0 1%;
	z-index: 1;
}
form label input {
	font: 14px Helvetica, Arial, sans-serif;
	box-sizing: border-box;
	display: block;
	border: none;
	padding: 20px;
	width: 100%;
	/* float: left; */
	margin-bottom: 20px;	
	outline: none;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	border-top: 2px solid #ddd;
}
form label input::-webkit-input-placeholder {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #999;
	font: 15px Helvetica, Arial, sans-serif;
}
form label input::-moz-placeholder {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #999;
	font: 15px Helvetica, Arial, sans-serif;
}
form label input:-ms-input-placeholder {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #999;
	font: 15px Helvetica, Arial, sans-serif;
}
form label input::placeholder {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #999;
	font: 15px Helvetica, Arial, sans-serif;
}
form label input:focus, form label input.populated {
	padding-top: 28px !important;
	padding-bottom: 12px !important;
}
form label input:focus::-webkit-input-placeholder, form label input.populated::-webkit-input-placeholder {
	color: transparent;
}
form label input:focus::-moz-placeholder, form label input.populated::-moz-placeholder {
	color: transparent;
}
form label input:focus:-ms-input-placeholder, form label input.populated:-ms-input-placeholder {
	color: transparent;
}
form label input:focus::placeholder, form label input.populated::placeholder {
	color: transparent;
}
form label input:focus + span, form label input.populated + span {
	opacity: 1;
	top: 10px;
}
form label span {
	/*color: #35dc9b;*/
	color: rgba(235,9,0,1);
	font: 10px Helvetica, Arial, sans-serif;
	position: absolute;
	top: 0px;
	left: 20px;
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
form label em {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 10px;
	padding: 2px 0;
	color: #111;
	font-size: 10px;
	line-height: 12px;
	font-style: normal;
	width: 99%;
	background-color: transparent;
	border-top: 5px solid rgba(235,99,0,1);
	border-left: 1px solid transparent;
	border-right: 2px solid transparent;
	
	height: 0;
	
		

}

.selectable {
	width: 180px;
	height: 34px;	
	font: 16px Helvetica, Arial, sans-serif;
	border: none;
	background-color: #777;
	color: #fff;	
	cursor: pointer;     
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.selectable .msg {
	margin: 0 0 0 10px;
}

.svg {
	width: 10px;
}

.svg-inner {
	width: 100%;
	height: 0;
	position: relative;
}

.svg svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;	
}

.svg svg path {
	fill: #FFF;
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.buttons button:first-child {
	margin: 0 15px 0 0;
}



.selectable:hover {background-color: #222 } 
.selectable:focus { outline: none }


.subform {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.subform section:last-child {
	margin: 0 0 0 40px;
}

.client-select h3 {
	/* color: #FFF; */
	margin: 0 0 3px;
	font-size: 17px;
}

.client-select-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}



#soab-signature-html-wrap {
	width: 600px;
	height: 600px;	
	position: relative;
	/* background-size: 100% auto; */
	position: relative;
	margin: 0 auto;
}

.soab-signature-wrap-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.soab-signature-wrap-bg img {
	width: 100%;
	display: block;
}

#soab-signature-html {
	position: absolute;
	top: 280px;
	left: 10px;
	width: 500px;
	height: 240px;
	overflow: hidden;
}